





















import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { Office, Profile } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { ProfileFilter } from 'client-website-ts-library/filters';
import ProfileCards from '@/components/ProfileCards.vue';

@Component({
  components: {
    ProfileCards,
  },
})
export default class About extends Mixins(View) {
  private profiles: Profile[] = [];

  private office: Office | null = null;

  mounted() {
    const filter = new ProfileFilter();

    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });

    API.Profiles.Search(filter, true).then((profiles) => {
      this.profiles = profiles;

      if (window.location.hash === '#team') {
        const element = document.getElementById('team');
        const top = element!.offsetTop;
        window.scrollTo(0, top - 200);
      }
    });
  }
}
